<!-- <div class="card cursor-pointer overlay border border-2 card-bordered shadow" (click)="openDetailsView()">
  <div class="card-body overlay-wrapper text-left p-0 cardImageSection">
    <img class="w-100 rounded" [src]="s3logoUrl" />

    <div class="mb-0 nameSection">
      <h3 class="fw-bolder text-white fs-1x mb-0 text-truncate" ngbTooltip="{{ investor.organizationName }}">
        {{ investor.organizationName }}
      </h3>
      <div class="row w-100">
        <div class="col-2 text-truncate text-white w-100 fs-6 mb-2 fw-normal"
          ngbTooltip="{{ investor.investmentDetails }}">
          {{ investor.investmentDetails }}
        </div>
      </div>
    </div>

    <div class="compareButtonSection w-100" *ngIf=" profileData">
      <div class="overlay-layer card-rounded">
        <button class="btn" [ngClass]="isInvestorInCompareList ? 'btn-secondary ls-2 text-uppercase fw-boldest' : 'btn-theme text-white ls-2 text-uppercase fw-boldest'" [class.disabled]="
            !isInvestorInCompareList && isMaxComparableItemsSelected
          " (click)="
            isInvestorInCompareList
              ? removeFromCompare($event)
              : addToCompare($event)
          ">
          {{
          isInvestorInCompareList ? "Remove from Compare" : "Add to Compare"
          }}
        </button>
      </div>
    </div>
  </div>

  <div class="card-footer d-flex align-items-center p-2">
    <div class="col-md-2 col-xxl-2">
      <div class="symbol symbol-40px symbol-circle me-3">
        <ngx-avatars name="{{ investor.user?.name | slice: 0:1 }}" size="40" *ngIf="!avtarUrl">
        </ngx-avatars>
        <div class="symbol symbol-40px" *ngIf="avtarUrl">
          <img alt="Logo" src="{{ avtarUrl }}" style="border-radius: 100%" />
        </div>
      </div>
    </div>
    <div class="col-md-10 col-xl-12 col-xxl-10">
      <span class="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-4 d-block text-truncate ps-2"
        ngbTooltip="{{ investor.user?.name }}">
        {{ investor.user?.name | titlecase }}
      </span>
    </div>
  </div>
</div> -->


<div class="card cursor-pointer overlay border border-2 card-bordered shadow" (click)="openDetailsView()"
  >
  <div class="card-body overlay-wrapper text-left p-0 cardImageSection"
    [ngbPopover]="popContent" container="body" [placement]="['left', 'right','top']"triggers="mouseenter:mouseleave"
    popoverClass="popover-custom-width"
  >
    <img class="w-100 rounded" [src]="s3logoUrl" />


    <div class="badge rounded compare-badge" [ngClass]="isInvestorInCompareList ? 'badge-secondary' : 'badge-primary'"
      [class.disabled]="
            !isInvestorInCompareList && isMaxComparableItemsSelected
          " *ngIf="brandDetails?.features?.profile_comparison && profileData && isShowCompareButton" (click)="
          isInvestorInCompareList
              ? removeFromCompare($event)
              : addToCompare($event)">
      {{ isInvestorInCompareList ? "Remove from Compare" : "Add to Compare" }}
    </div>

    <div class="badge rounded compare-badge badge-dark" *ngIf="openedFrom === 'recommended'" (click)="
    hideInvestor($event)">
      Hide
    </div>


    <div class="mb-0 nameSection">
      <h3 class="fw-bolder  text-white fs-1x mb-3 text-truncate" [class.mb-2]="!investor?.investmentDetails?.length"
        ngbTooltip="{{ investor.organizationName }}">
        {{ investor.organizationName }}
      </h3>

      <div class="row elevator-pitch-layer w-100" *ngIf="investor.investmentDetails?.length&&brandDetails?.features?.industries_technologies_section">
        <div class="col-2  text-white w-100 fs-6 mb-2 fw-normal mt-2">
          <span class="badge badge-search-cards text-white opacity-10 fw-bold mb-2 fs-7 me-3"
            *ngFor="let industry of investor.investmentDetails.slice(0,3)">
            {{ industry }}
          </span>

          <span class="badge badge-search-cards text-white opacity-10 fw-bold mb-2 fs-7 me-3"
            [ngbTooltip]="investor.investmentDetails.join(', ')"
            *ngIf="investor.investmentDetails.length > 3">+{{investor.investmentDetails.length - 3}}</span>
        </div>
      </div>


    </div>
  </div>

  <div class="card-footer d-flex align-items-center p-3 w-100">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex  align-items-center overflow-hidden">
          <span class="badge  badge-secondary text-dark  badge-custom-search-page fs-8 bg-white text-truncate"
            style="max-width: 40%;" [ngbTooltip]="investor?.organizationType?.name">
            <span class="text-truncate">
              {{investor?.organizationType?.name || 'N/A'}}
            </span>
          </span>

          <span class="badge  badge-secondary text-dark ms-2 badge-custom-search-page fs-8 bg-white text-truncate"
            style="max-width: 40%;" *ngIf="investor?.ticketSizeMin && investor?.ticketSizeMax">
            <span class="text-truncate">
              ${{
                investor?.ticketSizeMin
              | numberFormatter
              }}
              - ${{
                investor?.ticketSizeMax
              | numberFormatter
              }}
            </span>
          </span>
      </div>

      <div *ngIf="connectionObj && avtarSize === 'large'" style="width: 25px;">
        <span [ngbTooltip]="connectionTooltip">
          <i class="bi bi-patch-check-fill fs-1 header-icons-font-size"
          [ngClass]="{
            'text-green':connectionObj.connectionStatus === 'accepted'
          }"></i>
        </span>
      </div>

    </div>

    <!-- <div class="col-md-2 col-xxl-2">
      <div class="symbol symbol-circle me-3"
        [ngClass]="{'symbol-40px' : avtarSize === 'large' , 'symbol-25px' : avtarSize === 'small'}">
        <ngx-avatars name="{{ investor.user?.name | slice: 0:1 }}" [size]="avtarSize === 'small'? 20: 40"
          *ngIf="!avtarUrl">
        </ngx-avatars>
        <div class="symbol " [ngClass]="{'symbol-40px' : avtarSize === 'large' , 'symbol-25px' : avtarSize === 'small'}"
          *ngIf="avtarUrl">
          <img alt="Logo" src="{{ avtarUrl }}" style="border-radius: 100%" />
        </div>
      </div>
    </div>
    <div class="col-md-10 col-xl-12 col-xxl-10 text-truncate">
      <span class="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-4 d-block text-truncate ps-2"
        ngbTooltip="{{ investor.user?.name }}">
        {{ investor.user?.name | titlecase }}
      </span>
    </div> -->
  </div>
</div>
<ng-template #popContent>
  <div [innerHTML]="(investor.shortDescription ||  investor.aboutUs || '') | truncate:500 | decodeHtmlString">
  </div>
</ng-template>
