export interface IEvent {
  id: number
  uuid: string
  createdAt: string
  modifiedAt: string
  deletedAt: any
  eventImage: string
  eventTitle: string
  eventType: string
  eventOutComeType: string
  eventCode: string
  eventDescription: string
  challengeTypes: any
  closeApplications: boolean
  hasDeadline: boolean
  deadline_date: any
  status: boolean
  topics: Topic[]
  formId: number
  partnerId: number
  partner: Partner
  form: IEventForm
}

export interface IDynamicForm {
  id: number
  uuid: string
  status: boolean
  createdAt: string
  modifiedAt: string
  deletedAt: any
  formTitle: string
  formCode: string
  accountType: string
  fields: Field[]
  autoSaveOn: boolean
  description: string;
  useFormAs : string;
  applicationsClosed: boolean;
  visibility : 'public' | 'private'
  submitted?: boolean
  autoSaveTimer?: number
  bannerImage?:string
}

export interface Topic {
  name: string
  timeLimit: number
}

export interface Partner {
  id: number
  uuid: string
  createdAt: string
  modifiedAt: string
  deletedAt: any
  organization_name: string
  logo: string
  coverImage: string
  description: string
  brandColor: string
  headerBackColor: string
  pageBackColor: string
  website: string

  termsCheck: boolean
  terms: string
  privacy: string
}

export interface IEventForm {
  id: number
  uuid: string
  createdAt: string
  modifiedAt: string
  deletedAt: any
  name: string
  fields: Field[]
  status: boolean
  autoSaveOn: boolean
  partnerId: number
}

export interface Field {
  key: string
  name: string
  type: string
  fields: Field2[]
  columns: any[]
  visiblity: Visiblity
  multi_value: string
  multi_value_maximum: string
  multi_value_minimum: string
}

export interface Visiblity {
  show: string
  field: string
  value: string
  condition: string
}

export interface Column {
  name: string
  fields: Field2[]
}

export interface Field2 {
  name: string
  placeholder?: string
  type: DynamicFormFieldType
  required: string
  min_chars?: string
  max_chars?: string
  default_value: any
  rich_editor: string
  min_value: any
  max_value: any
  select_type: string
  options: string[]
  max_selections?: string
  max_file_size: any
  file_types: string[]
  key: string
  upload_multiple: '1' | '0'
  max_file_selections: string
  link_master_data: string
  master_data_source: string
  col_size: string
  regex: string
  visiblity: Visiblity
  divider: IDivider
  step:number;
  columns:string[];
  rows:string[];
  layout_view:string
}
export interface IDivider {
  margin: string
  add_border: string
  border_size: string
  border_style: string
  border_color: string
}

export enum DynamicFormFieldType {
  text_field = 'text_field',
  text_area = 'text_area',
  url_field = "url_field",
  number_field = "number_field", // TODO: recheck - add max and min
  country_picker = "country_picker",
  date_picker = "date_picker",
  selectbox_field = "selectbox_field",
  phone_number_field = 'phone_number_field',

  file_upload = "file_upload",
  radio_field = "radio_field",
  checkbox_field = "checkbox_field",
  divider = "divider",

  range_slider="range_slider",
  checkbox_grid="checkbox_grid",
  radio_grid="radio_grid",
  text_input_grid='text_input_grid',
  number_input_grid='number_input_grid'
}
export interface IParticipationDetails {
  id: number
  uuid: string
  createdAt: string
  modifiedAt: string
  deletedAt: any
  eventId: number
  userId: number
  data: any
  juryMembers: any
  isSubmitted: boolean
  participantCode: string
  videoId: number
  video: Video
  termsCheck: boolean
  submitted?: boolean
}

export interface Video {
  id: number
  uuid: string
  createdAt: string
  modifiedAt: string
  deletedAt: any
  videoID: string
  name: string
  description: any
  compiledUrl: any
  compiledJobId: any
  compiledThumbUrl: any
  eventCode: string
  videoStatus: 'draft' | 'processing' | 'deleted' | 'completed',
  userId: number
  eventId: number
  customLogo: any
  customLogoPosition: string
  addCustomLogo: boolean
  removeWatermark: boolean
  addCta: boolean
  showGraphicToVideo: boolean
  collectFeedback: boolean
  isPrivate: boolean
  feedbackTypes: any
  totalCompiles: number
  totalDownloads: number
  pitchCategoryUid: any
}




export interface IPublicProfileView {
  formTitle: string
  formCode: string
  sections: IIPublicProfileViewSection[]
}

export interface IIPublicProfileViewSection {
  sectionTitle: string
  sectionCode: string
  is_array: boolean
  fields: IIPublicProfileViewField[]
  data: Record<string, any>[]
}

export interface IIPublicProfileViewField {
  fieldTitle: string
  fieldType: DynamicFormFieldType
  fileTypes: string[]
  fieldCode: string
  fieldSize: string;
  data: any
}
