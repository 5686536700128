import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { ICalenderEventModal } from 'src/app/core/domain/meetings.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { MeetingService } from 'src/app/core/service/meeting.service';
import { getProfileData } from 'src/app/core/state/profile';
import { getGlobalSettings } from 'src/app/core/state/global';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { GlobalService } from 'src/app/core/service/global.service';
import 'add-to-calendar-button';
import { formatEnumStringForUI } from '../../utils/common-methods';
import { ProfileService } from 'src/app/core/service/profile.service';
import { LightGalleryAllSettings } from 'lightgallery/lg-settings';
import lgZoom from 'lightgallery/plugins/zoom';

@Component({
  selector: 'app-event-details-modal',
  templateUrl: './event-details-modal.component.html',
  styleUrls: ['./event-details-modal.component.scss']
})
export class EventDetailsModalComponent implements OnInit {
  globalSettings: IGlobalSettings;

  settings: Partial<LightGalleryAllSettings> = {
    counter: false,
    plugins: [lgZoom],
  };
  @Input() modalInst: NgbModalRef;

  @Input() modalData: {
    action: string;
    event: any;
  };

  @Input() isRescheduling = false;

  private destroyed$: Subject<void> = new Subject();
  public profileDetails$: Observable<IProfileDetails>;
  profileDetails: IProfileDetails;
  selectedResponse;

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private meetingService: MeetingService,
    private router: Router,
    private readonly store: Store,
    private uiLoader: NgxUiLoaderService,
    private globalService: GlobalService,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    console.log('modalData', this.modalData)
    if (this.modalData?.event?.checkIfResponseExists?.responseAction) {
      console.log('=========================')
      this.selectedResponse = { value: this.modalData.event?.checkIfResponseExists?.responseAction }
    }

    this.profileDetails$ = this.store.pipe(select(getProfileData));
    this.profileDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) { this.profileDetails = res; }
    })
    this.store.pipe(select(getGlobalSettings)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  get isInPast() {
    return moment(this.modalData?.event?.date).isBefore(moment()  , 'date')
  }

  get meetingUrl() {
    const event: any = this.modalData.event
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    return window.location.origin + link
  }
  meetingDetail(event) {
    this.closeModal();
    // this.router.navigate(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid])
    const link = this.router.serializeUrl(this.router.createUrlTree(event.meetingType === 'job-interview' ? ['/job-interview/', event.uuid] : ['/meetings/meeting/' + event.uuid]));
    window.open(link, '_blank');
  }


  closeModal(): void {
    this.modalInst.close({});
  }


  tConvert(time, date) {
    return time
  }

  get eventDate() {
    return new Date(this.modalData.event.date)
  }


  formatTo24Hour(timeAMPM) {
    const time24Hour = moment(timeAMPM, 'h:mm A').format('HH:mm');
    return time24Hour
  }

  get existingAttendence() {
    return this.bookingResponse || this.modalData?.event?.checkIfResponseExists
  }

  bookingResponse;
  async selectResponse(item) {
    const event: any = this.modalData.event

    this.selectedResponse = item;
    const existingAttendence = this.bookingResponse || event.checkIfResponseExists
    if(existingAttendence?.uuid) {
      const res = await this.globalService.updateWebinarEventBooking(event.uuid, existingAttendence?.uuid, item.value).toPromise()
      if(res) {
        this.bookingResponse = res
      }

      if(this.modalData?.event?.checkIfResponseExists) {
        this.modalData.event.checkIfResponseExists.responseAction = item.value
      }
    }else{
      this.bookingResponse = await this.globalService.bookEventSlot(event.uuid, null, null, item.value).toPromise()
    }
    // this.globalService.eventResponse(event.id, item.value).subscribe()
  }


  get speakerProfileLink() {
    if (this.modalData?.event?.speakerCompanyDetails) {
      return this.profileService.getProfileLink(
        this.modalData?.event?.speakerCompanyDetails?.uuid,
        this.modalData?.event?.speakerCompanyDetails?.name,
        this.modalData?.event?.speakerDetails?.accountType
      )
    }
    return ''
  }


  formatEnumStringForUI(i) {
    return formatEnumStringForUI(i)
  }
  get mainSpeaker(){
    const speaker = this.modalData.event?.speakerDetails;
    const speakerCompanyDetails = this.modalData.event?.speakerCompanyDetails;
    if (!speaker) {
      return {}
    }
    return {
        "bio": this.modalData.event.speakerBio,
        "name": speaker?.name,
        "email": "",
        "mobile": null,
        "imageKey": null,
        "linkedin":this.modalData.event.speakerLinkedin,
        "existingImage": speakerCompanyDetails?.logo || speaker.avatar,
        "speaker_image": speakerCompanyDetails?.logo || speaker.avatar,

    }
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  handleMainSpeakerClick() {
    const link = this.profileService.getProfileLink(this.modalData.event.speakerCompanyDetails.uuid, this.modalData.event.speakerCompanyDetails.name, this.modalData.event.speakerDetails.accountType)
    this.router.navigateByUrl(link)
    this.closeModal()
  }

}
