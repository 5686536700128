import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CorporateInformationModel, CorporateProfileCompleteness, InvestorProfileCompleteness, MentorInformationModel, ServiceProviderInformationModel, ServiceProviderProfileCompleteness } from 'src/app/core/domain';
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import * as ServiceProviderDashboardAction from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.action';
import { providingFundRequestFault, providingFundRequestSuccess } from 'src/app/core/state/investor-dashboard';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { getServiceProviderInfo } from 'src/app/core/state/service-provider-info';
import { getServiceProviderProfileCompleteness } from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.selectors';
import { createSlug } from '../../utils/common-methods';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareProfileModalComponent } from '../share-profile-modal/share-profile-modal.component';
import { getBrandDetails } from 'src/app/core/state/global';
import { IBrandDetails } from 'src/app/core/domain/brand.model';

@Component({
  selector: 'app-service-provider-profile-completeness',
  templateUrl: './service-provider-profile-completeness.component.html',
  styleUrls: ['./service-provider-profile-completeness.component.scss']
})
export class ServiceProviderProfileCompletenessComponent implements OnInit, OnDestroy {

  @Input() isUnderApproval = false;


  public profileCompleteness: ServiceProviderProfileCompleteness;
  private destroyed$: Subject<void> = new Subject();
  missingFields = [];
  mentorInfo: ServiceProviderInformationModel;
  serviceProviderProfilePageUrl
  @Input() showShareFeedback = true;
  @Input() showEditProfileBtn = true;
  brandDetails: Partial<IBrandDetails>;

  constructor(
    private readonly store: Store,
    private toastAlertService: ToastAlertService,
    private router: Router,
    public modal: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.getProfileCompleteness();
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;
      }
    })
    this.store.pipe(select(getServiceProviderInfo)).pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.mentorInfo = res.data;
        this.serviceProviderProfilePageUrl = `/service-provider/profile/${this.mentorInfo.uuid}/${createSlug(this.mentorInfo.name)}`;
      }
    })


    this.store.pipe(select(providingFundRequestSuccess)).pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.toastAlertService.showToast(res);
      }
    })

    this.store.pipe(select(providingFundRequestFault)).pipe(takeUntil(this.destroyed$), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.toastAlertService.showToast(res, 'error');
      }
    })

  }

  getProfileCompleteness() {
    this.store.dispatch(new ServiceProviderDashboardAction.GetProfileCompleteness());
    this.store.pipe(select(getServiceProviderProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.profileCompleteness = res;
        this.isUnderApproval = this.profileCompleteness && this.profileCompleteness?.isApprovalRequested && !this.profileCompleteness?.canToggleStatus  && !this.profileCompleteness?.isApproved;

        this.getMissingFields();
        if (localStorage.getItem('approval-modal') !== 'hide') {
          this.checkAndShowModalForSubmitProfile();
        }

      }
    })
  }


  getMissingFields = () => {
    this.missingFields = [];

    Object.keys(this.profileCompleteness.forms).forEach((e: string) => {
      this.missingFields = [...this.missingFields, ...(this.profileCompleteness.forms[e].missingFields || [])]
    })
    console.log('this.missingFields', this.missingFields);
  }


  onToggleChange($event) {
    this.store.dispatch(new InvestorDashboardAction.ProvidingFundRequest);
  }



  checkAndShowModalForSubmitProfile() {
    if (this.profileCompleteness.percentage >= 100 && !this.profileCompleteness.isApprovalRequested && !this.profileCompleteness.isRejected && !this.profileCompleteness?.isApproved) {
      Swal.fire({
        title: 'Awesome! your profile is ready to go live.',
        text: 'Submit to start connecting with the community.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Submit',
        showDenyButton: true,
        denyButtonText: 'Preview',
        denyButtonColor: '#000',
        allowOutsideClick: false,
        allowEscapeKey: false,
        preDeny: () => {
          const slug = this.mentorInfo.name.split(' ').join('-').toLocaleLowerCase();
          const url = this.router.serializeUrl(this.router.createUrlTree(['/service-provider/profile/' + this.mentorInfo.uuid, slug]));
          window.open(url, '_blank');
          return false;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.requestApproval();
        } else {
          if (result.isDenied) {
            return false;
          } else {
            localStorage.setItem('approval-modal', 'hide');
          }
        }
      });
    }
  }

  requestApproval() {
    console.log('requestApproval')
    this.store.dispatch(new ServiceProviderDashboardAction.SendRequestApproval());
    setTimeout(() => {
      this.router.navigate(['/service-provider/dashboard']);
    }, 1500);

  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }



  handleShareButton() {
    this.modal.open(
      ShareProfileModalComponent, { centered: true, size: 'lg' }
    )
  }

  get isProfileCompleted ( ) {
    return this.profileCompleteness?.percentage >= 100 && this.profileCompleteness?.isApproved;
  }


  get isLimitedAccess() {
    return this.profileCompleteness?.isRejected && this.brandDetails?.features?.limited_access && this.profileCompleteness?.approvalStatus === 'limited_access'
  }
}
