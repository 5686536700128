import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";
import { Store, select } from "@ngrx/store";
import * as GlobalActions from "../state/global/global.action";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { ACCOUNT_TYPE, InvestorTypes } from "../domain";
import { getProfileData } from "../state/profile";
import { IProfileDetails } from "../domain/profile.model";
import * as CorporateDashboardAction from 'src/app/core/state/corporate-dashboard/corporate.dashboard.action';
import * as ServiceProviderDashboardAction from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.action';
import * as MentorDashboardAction from 'src/app/core/state/mentors-dashboard/mentors.dashboard.action';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";

@Injectable({
  providedIn: "root"
})
export class FormManagementService {
  profileData: IProfileDetails;

  constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store) {
    this.store.pipe(select(getProfileData))
      .pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          this.profileData = res;
        }
      })
  }

  getProfileCompleteness() {
    if(this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {
      if(this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        return this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
      }
      return this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
    }
    if(this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      return this.store.dispatch(new CorporateDashboardAction.GetProfileCompleteness());
    }
    if(this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      return this.store.dispatch(new ServiceProviderDashboardAction.GetProfileCompleteness());
    }
    if(this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      return this.store.dispatch(new MentorDashboardAction.GetProfileCompleteness());
    }
    if(this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
    }
  }


  public getFormsList(userType?:string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + 'list' + (userType ? `/${userType}`: '');
    return this.http.get(url).pipe(
      map((response: any) => {
        this.store.dispatch(new GlobalActions.SetFormsList(response?.data))
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }


  public getFormsByUUID(uuids?:string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + 'uuids/'+uuids;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }
  public getFormsByCode(code?:string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + 'code/'+code;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }

  public getFormsSubmission(formUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + 'submission/' + formUUID;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }

  // public getSingleFormsSubmission(formUUID: string): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + 'submission/' + formUUID;
  //   return this.http.get(url).pipe(
  //     map((res:any) => {
  //       return res.data
  //       // this.getProfileCompleteness();
  //     }),
  //     catchError((fault: HttpErrorResponse) => {
  //       this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
  //       return throwError(() => fault);
  //     })
  //   );
  // }


  public saveFormsSubmission(formUUID: string, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + 'submission/' + formUUID;
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.getProfileCompleteness();
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }

  public savePublicFormsSubmission(formUUID: string, payload): Observable<any> {

    console.log(payload);

    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT_PUBLIC) + 'submission/' + formUUID + '/submit';
    return this.http.post(url, payload).pipe(
      tap(() => {
        // this.getProfileCompleteness();
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchPublicFormsSubmission(formUUID: string, payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT_PUBLIC) + 'submission/' + formUUID + '/fetch';
    return this.http.post(url, payload).pipe(
      tap(() => {
        // this.getProfileCompleteness();
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }

  public checkFormAccess(formUUID: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + formUUID + '/check-access';
    return this.http.get(url).pipe(
      tap(() => {
        // this.getProfileCompleteness();
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      })
    );
  }

  public updatePublicFile(formUUID, file: File) {
    const payload = new FormData();
    payload.append('file', file);

    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT_PUBLIC) + `submission/${formUUID}/upload-file`;
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      }),
    );
  }


  public updateFile(formUUID, file: File) {
    const payload = new FormData();
    payload.append('file', file);

    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FORMS_MANAGEMENT) + `submission/${formUUID}/upload-file`;
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching forms', 'error');
        return throwError(() => fault);
      }),
    );
  }


}
