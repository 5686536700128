import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LightGalleryAllSettings } from 'lightgallery/lg-settings';
import { videoSettings } from 'lightgallery/plugins/video/lg-video-settings';
import lgZoom from 'lightgallery/plugins/zoom';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { IIPublicProfileViewField, DynamicFormFieldType, IPublicProfileView, IEventForm, IIPublicProfileViewSection } from 'src/app/core/domain/event.interface';
import { FormManagementService } from 'src/app/core/service/form-management.service';
import { PublicApiService } from 'src/app/core/service/public-api.service';
import { getFormsList } from 'src/app/core/state/global';
import { ImagekitUrlAppenderPipe } from 'src/app/shared/pipes/imagekit-url-appender.pipe';
import { sleep } from 'src/app/shared/utils/common-methods';

@Component({
  selector: 'app-public-profile-dynamic-single-form-view',
  templateUrl: './public-profile-dynamic-single-form-view.component.html',
  styleUrls: ['./public-profile-dynamic-single-form-view.component.scss']
})
export class PublicProfileDynamicSingleFormViewComponent implements OnInit, OnDestroy {
  @Input() item: IPublicProfileView ;
  private destroyed$: Subject<void> = new Subject();
  formObject: IEventForm;
  activeIds = []


  constructor(
    private imagekit: ImagekitUrlAppenderPipe,
    private store: Store,
    private publicApiService: PublicApiService,
    private formManagemenetService: FormManagementService,
  ) { }

  settings: Partial<LightGalleryAllSettings> = {
    counter: false,
    plugins: [lgZoom],
  };

  ngOnInit(): void {
  }




  async getFormsList() {
    // this.store.pipe(select(getFormsList)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
    this.formManagemenetService.getFormsByCode(this.item.formCode)
    .subscribe(async (res) => {
      // const listObj = JSON.parse(JSON.stringify(res))
      let formInst  =res;
      // const listArr = Object.values(listObj)
      // listArr.forEach((l: any) => {
      //   console.log('lllll', l, listObj, this.item.formCode, this.item)
        // if (l && l.length) {
        //   const found = l.find(e => e.formCode === this.item.formCode)
        //   if(found) {
        //     formInst = found
        //   }
        //   console.log('formInst', formInst)
        // }
      // })

      if (formInst) {
        // this.eventDetails = {
        //   form: formInst, partner: {
        //     "logo": "partners/5764ba4e-1d0b-4f81-ae87-794b8ef16d6d/logo-1e84bef72a5e0a04b35e78b96e90faed.png",
        //     "coverImage": "partners/5764ba4e-1d0b-4f81-ae87-794b8ef16d6d/header_cover_image-cba96e47a4b76f255d94c6b2ef4065d0.jpg",
        //     "description": "&lt;p&gt;A unique program by domain specific venture funds, successful founders and industry experts with fund infusion on day 1 along with end to end Go-To-Market mentorship and support during acceleration&lt;/p&gt;",
        //     "brandColor": "#c0790f",
        //     "headerBackColor": "#f9f6f0",
        //     "pageBackColor": "#f9f6f0",
        //   }
        // }

        this.formObject = formInst;
        this.activeIds = this.formObject.fields.map((e) => 'Video_Startup_form-header' + e.key);

        (this.formObject?.fields || []).forEach((field) => {
          field.fields = field.fields.map((e) => {
            return JSON.parse(e as unknown as string)
          })
          console.log('fieldfieldfield', field)
        })


        for (let index = 0; index < (this.formObject?.fields?.length || 0); index++) {
          const fields = this.formObject.fields[index]['fields'];

            for (let k = 0; k < fields.length; k++) {
              const field = fields[k];
              if (field.master_data_source) {

                try {
                  const optionsList: any = await this.publicApiService.fetchGlobalSettingsByModal(field.master_data_source).toPromise();
                  field.options = (optionsList[field.master_data_source] || optionsList || []).map(e => e.name)
                } catch (error) {}
              }
            }

        }
        // this.buildForm();

        console.log('formObject', this.formObject)
      }

      // if (res[this.profileData.accountType]) {
      //   this.formsList = JSON.parse(JSON.stringify(res[this.profileData.accountType]))
      // }
      // // this.formsList = [...(res[this.profileData.accountType] || [])]
      // console.log('getFormsList', this.formsList)
    })

  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log(';datadata',this.item)
    this.getFormsList()
  }

  isFieldVisbile = (field: IIPublicProfileViewField) => {
    let visible = true;
    // || (Array.isArray(field.data) && !field.data.length)
    if (field.fieldTitle === 'Field Divider' || !field.data ) {
      visible = false
    }
    return visible
  }

  isDataAvailable = (field: IIPublicProfileViewField) => {
    if(!field.data || (Array.isArray(field.data) && !field.data.length)) {
      return false
    }
    return true
  }


  isImg (data, field: IIPublicProfileViewField) {
    if(field.fieldType === DynamicFormFieldType.file_upload ) {
      const str =  data[field.fieldCode]?.toString();
      if(str?.includes('jpg') || str?.includes('png') || str?.includes('jpeg')) {
        return true
      }
      return false
    }
    return false
  }

  isFile(field: IIPublicProfileViewField) {
    return field.fieldType === DynamicFormFieldType.file_upload
  }

  async downloadFile(files) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      const link = document.createElement("a");
      link.target = '_blank'
      link.href = this.imagekit.transform(file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      await sleep(500)
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  get flatFieldValuesMapped() {
    const vals= {}
    this.item.sections.forEach(s=>{
      s.fields.forEach((e)=>{
        vals[e.fieldCode] = e.data
      })
    })
    return vals
  }

  isSectionVisible(sectionMeta: IIPublicProfileViewSection) {
    const field = this.formObject.fields.find((e)=>e.key === sectionMeta.sectionCode)
    let visible = false;
    const visiblitySetting = field.visiblity
    if (!visiblitySetting.show || visiblitySetting.show === 'null') {
      visible = true
      return visible
    }
    // console.log('field--->', field, this.flatFieldValuesMapped)
    // console.log('field.visiblity.show', visiblitySetting, this.getFormValues())

    const formValues = this.flatFieldValuesMapped;

    if (visiblitySetting.condition === 'is') {
      if (this.checkValueMatch(formValues[visiblitySetting.field], visiblitySetting.value)) {
        visible = true
      }
    } else {
      if (!this.checkValueMatch(formValues[visiblitySetting.field], visiblitySetting.value)) {
        visible = true
      }
    }

    return visible
  }




  isFieldVisible(fieldMeta: IIPublicProfileViewField) {
    let visible = false;
    let field;
    this.formObject.fields.forEach((s)=>{
      const foundField= s.fields.find((e)=>{
        return e.key === fieldMeta.fieldCode
      })
      if(foundField) {
        field = foundField
      }
    })

    const visiblitySetting = field.visiblity


    if (!visiblitySetting.show || visiblitySetting.show === 'null' || visiblitySetting.field === 'none') {
      visible = true
    }
    const formValues = this.flatFieldValuesMapped

    if (visiblitySetting.condition === 'is') {
      if (this.checkValueMatch(formValues[visiblitySetting.field], visiblitySetting.value)) {
        visible = true
      }
    } else {
      if (!this.checkValueMatch(formValues[visiblitySetting.field], visiblitySetting.value)) {
        visible = true
      }
    }

    return visible
  }

  checkValueMatch(val1, val2) {
    if(Array.isArray(val1)) {
      return val1.includes(val2);
    }
    return val1 === val2
  }

}
