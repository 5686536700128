import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { IStartUpSearchResponse } from 'src/app/core/domain/search.model';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { ConnectionsService } from 'src/app/core/service/connections.service';
import { ProfileService } from 'src/app/core/service/profile.service';
import { RecommendedService } from 'src/app/core/service/recommended.service';
import { getStartupsToCompare } from 'src/app/core/state/compare';
import * as CompareActions from 'src/app/core/state/compare/compare.action';
import { getBasicConnectionList, getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import { getProfileData } from 'src/app/core/state/profile';
import { IBasicConnectionList } from 'src/app/modules/connections/connections.model';
import { MAX_COMPARISION } from 'src/app/shared/constants/constants';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-startup-search-card',
  templateUrl: './startup-search-card.component.html',
  styleUrls: ['./startup-search-card.component.scss']
})
export class StartupSearchCardComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject();

  @Input('startup') startup: IStartUpSearchResponse;
  @Input('isShowCompareButton') isShowCompareButton: boolean = true;
  @Input('disableHover') disableHover: boolean = false;
  @Input('avtarSize') avtarSize: 'small' | 'large' = 'large';
  @Input() appliedFilters;
  @Input() showCompleteness;
  @Input() openedFrom: 'search' | 'recommended' = 'search';
  @Output() hideRecommendedStartup = new EventEmitter()
  public globalSettings$: Observable<IGlobalSettings>;

  globalSettings: IGlobalSettings;
  ACCOUNT_TYPE = ACCOUNT_TYPE

  s3logoUrl = '';

  avtarUrl = '';
  isStartupInCompareList = false;
  isMaxComparableItemsSelected = false;

  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  profileComplenessClass = 'bg-primary';
  brandDetails: Partial<IBrandDetails>;
  connectionObj: IBasicConnectionList;

  constructor(
    private store: Store,
    private router: Router,
    private recommendedService: RecommendedService,
    private profileService: ProfileService,
    private connectionsService: ConnectionsService,
    private toastAlertService: ToastAlertService,
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));

    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      }
    })
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        if (this.startup.companyLogo) {
          this.s3logoUrl = res.imgKitUrl + this.startup.companyLogo + '?tr=w-300,h-300,cm-pad_resize';
        } else {
          this.s3logoUrl = res.assetsImgKitUrl + '/images/no-logo-uploaded.png' + '?tr=w-200,h-200,cm-pad_resize';
        }

        if (this.startup.user?.avatar) {
          this.avtarUrl = res.imgKitUrl + this.startup.user.avatar + '?tr=w-300,h-300,cm-pad_resize';
        }
      }
    });
    this.handleSubscriptions();
    this.getProfileCompletnessClass()

    // this.connectionObj = this.connectionsService.isConnected(this.startup)
  }

  get connectionTooltip () {
    if(this.connectionObj.connectionStatus === 'accepted') {
      return `You are connected to ${this.connectionObj.companyName} since ${this.connectionObj.acceptedAt}`
    }else{
      return `Connection request pending acceptance from either side.`
    }
  }

  getProfileCompletnessClass() {
    if (this.startup && this.startup.profileCompletenessPercent) {
      const percent = this.startup.profileCompletenessPercent;
      if (percent > 90) {
        this.profileComplenessClass = 'bg-warning'
      }
      if (percent >= 100) {
        this.profileComplenessClass = 'bg-success'
      }
    }
  }

  handleSubscriptions() {
    this.store.select(getBasicConnectionList).pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged()
    ).subscribe((list) => {
      if (list) {
        this.connectionObj = this.connectionsService.isConnected(this.startup)
      }
    })

    this.store.select(getStartupsToCompare).pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged()
    ).subscribe((list) => {
      if (list) {
        this.isStartupInCompareList = list.includes(this.startup.uuid);
        this.isMaxComparableItemsSelected = list.length >= MAX_COMPARISION;
      }
    })
  }

  addToCompare($event: Event) {
    $event.stopPropagation();
    this.store.dispatch(new CompareActions.AddStartUpToCompare(this.startup.uuid));
  }

  removeFromCompare($event: Event) {
    $event.stopPropagation();
    this.store.dispatch(new CompareActions.RemoveStartUpFromCompareList(this.startup.uuid));
  }

  openDetailsView() {

    if (this.profileData && this.profileService.isProfileLocked) {
      Swal.fire({
        title: 'Unauthorized Access',
        text: 'To access this profile, you need to have your profile approved.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Ok'
      }).then((result) => { });
      return;
    }

    const slug = this.startup.companyName.split(' ').join('-').toLocaleLowerCase();
    const url = this.router.serializeUrl(this.router.createUrlTree(['/startups/profile/' + this.startup.uuid, slug]));
    window.open(url, '_blank');
  }

  hideStartup($event: Event) {
    $event.stopPropagation();
    this.hideRecommendedStartup.emit(this.startup.uuid)
  }

  get programs () {
    return (this.startup.programs || []).map(e=>e.programTitle)
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
